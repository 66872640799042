<template>
  <Dialog :common-dialog="reassignScheduleDialog" :dialog-width="dialogWidth">
    <template v-slot:title> Milestone Invoice </template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-row>
            <v-col md="12">
              <label class="font-weight-700 font-size-16 required"
                >Invoice Date</label
              >
              <!--  <DatePicker :class="'nml-4'" :pageLoading="formLoading" solo :placeholder="'Invoice Date'"
                                :default-date="invoiceData.invoice_date" :min-date="todayDate"
                                v-model="invoiceData.invoice_date"></DatePicker> -->
              <DatePicker
                :dataLoading="formLoading"
                solo
                :default-date="invoiceData.invoice_date"
                :placeholder="'Reminder Date '"
                v-model="invoiceData.invoice_date"
              ></DatePicker>
            </v-col>
            <!--  <v-col md="6">
              <div class="">
                <label
                  class="font-weight-700 font-size-16 required"
                  for="one-off-start-time"
                  >Payment Status</label
                >
                <v-autocomplete
                  :disabled="formLoading"
                  :loading="formLoading"
                  dense
                  color="cyan"
                  :rules="[
                    validateRules.required(
                      invoiceData.payment_status,
                      'Payment Status'
                    ),
                  ]"
                  filled
                  id="Payment-Status"
                  :items="statusList"
                  v-model.trim="invoiceData.payment_status"
                  placeholder="Payment Status"
                  label="Payment Status"
                  solo
                  item-value="value"
                  item-text="text"
                  flat
                  item-color="cyan"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 10px; width: 10px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        style="max-width: 60px !important"
                      >
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16"
                >Invoice Amount
              </label>
              <v-text-field
                label="Invoice Amount"
                readonly
                dense
                class="nml-4"
                filled
                solo
                flat
                color="cyan"
                v-model="invoiceData.invoice_amount"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <div class="">
                <label
                  class="font-weight-700 font-size-16"
                  for="one-off-start-time"
                  >Collected Amount
                </label>
                <v-text-field
                  label="Collected Amount"
                  dense
                  class="nml-4"
                  filled
                  solo
                  flat
                  color="cyan"
                  v-model="invoiceData.collected_amount"
                  v-on:change="getCollected"
                  type="number"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <label
                class="font-weight-700 font-size-16"
                for="one-off-start-time"
                >Remark</label
              >
              <v-textarea
                class="nml-4"
                id="cancel-reason"
                auto-grow
                dense
                filled
                color="cyan"
                placeholder="Enter Remark..."
                solo
                flat
                v-model="invoiceData.remark"
                row-height="20"
                v-on:keypress="(e) => manageLimit(e)"
                v-on:paste="(e) => onPaste(e)"
              />
              <div class="text-right">
                {{ invoiceData.remark ? invoiceData.remark.length : 0 }}/100
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <label
                class="font-weight-700 font-size-16"
                for="one-off-start-time"
                >Attachments</label
              >
              <table width="100%" style="table-layout: fixed">
                <tr
                  v-for="(row, index) in attachments"
                  :key="`create-visit-attachment-${index}`"
                >
                  <td class="px-0 py-0">
                    <v-file-input
                      hide-details
                      class="px-0"
                      dense
                      filled
                      solo
                      flat
                      v-model="row.file"
                      placeholder="Select File"
                      prepend-icon=""
                      prepend-inner-icon="$file"
                      v-on:change="updateFile(index, $event)"
                      color="cyan"
                    />
                  </td>
                  <td class="py-0">
                    <v-text-field
                      hide-details
                      class="px-0"
                      v-model="row.name"
                      placeholder="Name"
                      dense
                      filled
                      :suffix="row.ext ? `.${row.ext}` : ''"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                  <td class="px-0 py-0" width="10%" align="center">
                    <v-btn
                      :disabled="attachments && attachments.length == 1"
                      v-on:click="removeFile(index)"
                      icon
                      class="w-100"
                      color="red lighten-1"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pb-0" colspan="3">
                    <v-btn
                      color="cyan white--text"
                      class="custom-bold-button"
                      depressed
                      v-on:click="addMore"
                      tile
                      >Add More</v-btn
                    >
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <!--  -->
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        :disabled="!formValid || formLoading"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
/*   import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib"; */
import { POST, GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
/*   import { validateEmail } from "@/core/plugins/validation-mixin"; */
/*   import { PUT, QUERY } from "@/core/services/store/request.module";
  import AppConfiguration from "@/core/config/app.config"; */
import DatePicker from "@/view/pages/partials/Datepicker.vue";

import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    reassignScheduleDialog: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: 0,
    },
    updatedId: {
      type: Number,
      default: 0,
    },
    milestoneId: {
      type: Number,
      default: 0,
    },
    milestoneData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    updatedId: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param > 0) {
          this.getInvoice(param);
        }
      },
    },
    milestoneData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.invoiceData.invoice_amount =
            this.invoiceData.invoice_amount &&
            this.invoiceData.invoice_amount > 0
              ? this.invoiceData.invoice_amount
              : param.amount;
        }
      },
    },
  },
  data() {
    return {
      formLoading: false,
      formValid: true,
      startDatePicker: false,
      statusList: [
        { value: "pending", text: "Pending", color: "red accent-4" },
        { value: "complate", text: "Complete", color: "green accent-4" },
      ],
      engineerList: [],
      attachments: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
      milestone_invoice_id: 0,
      invoiceData: {
        invoice_date: moment().format("YYYY-MM-DD"),
        payment_status: null,
        collected_amount: 0,
        invoice_amount: 0,
        remark: null,
      },
    };
  },
  methods: {
    getInvoice(id) {
      if (id > 0) {
        this.milestone_invoice_id = id;
        const _this = this;
        _this.$store
          .dispatch(GET, { url: `milestone-invoice/${id}` })
          .then(({ data }) => {
            (_this.invoiceData.invoice_date = data.invoice_date
              ? data.invoice_date
              : moment().format("YYYY-MM-DD")),
              (_this.invoiceData.payment_status = data.payment_status
                ? data.payment_status
                : null),
              (_this.invoiceData.collected_amount = data.collected_amount
                ? data.collected_amount
                : 0),
              (_this.invoiceData.invoice_amount =
                data.invoice_amount && data.invoice_amount > 0
                  ? data.invoice_amount
                  : this.milestoneData.amount),
              (_this.invoiceData.remark = data.remark ? data.remark : null),
              (_this.invoiceData.milestone = data.milestoneId
                ? this.lodash.toSafeInteger(data.milestoneId)
                : 0),
              (_this.invoiceData.project = data.projectId
                ? this.lodash.toSafeInteger(data.projectId)
                : 0);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    getCollected() {
      if (
        Number(this.invoiceData?.collected_amount) >
        Number(this.invoiceData.invoice_amount)
      ) {
        this.invoiceData.collected_amount = Number(
          this.invoiceData.invoice_amount
        );
      }
    },
    close_dialog() {
      this.$emit("close", true);
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.attachments[index].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.attachments[index].name = null;
        this.attachments[index].suffix = null;
      }
    },
    addMore() {
      this.attachments.push({
        file: null,
        name: null,
        suffix: null,
      });
    },
    removeFile(index) {
      this.attachments.splice(index, 1);
      /* if (this.attachments.length < 1) {
        this.addMore();
      } */
    },
    submit() {
      const _this = this;
      _this.formLoading = true;
      let formData = new FormData();
      if (this.invoiceData && this.invoiceData.invoice_date) {
        formData.append(
          "invoice_date",
          this.invoiceData.invoice_date ? this.invoiceData.invoice_date : null
        );
      }
      if (this.invoiceData) {
        formData.append(
          "payment_status",
          Number(this.invoiceData.collected_amount) <
            Number(this.invoiceData.invoice_amount)
            ? "pending"
            : "complate"
        );
      }
      if (this.invoiceData && this.invoiceData.remark) {
        formData.append(
          "remark",
          this.invoiceData.remark ? this.invoiceData.remark : null
        );
      }
      if (this.invoiceData && this.invoiceData.invoice_amount) {
        formData.append(
          "invoice_amount",
          this.invoiceData.invoice_amount ? this.invoiceData.invoice_amount : 0
        );
      }
      if (this.invoiceData && this.invoiceData.collected_amount) {
        formData.append(
          "collected_amount",
          this.invoiceData.collected_amount
            ? this.invoiceData.collected_amount
            : null
        );
      }

      formData.append("milestone", this.milestoneId ? this.milestoneId : 0);
      formData.append("project", this.projectId ? this.projectId : 0);
      if (this.attachments && this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          if (
            this.attachments &&
            this.attachments[i] &&
            this.attachments[i].file
          ) {
            formData.append(
              `attachments[${i}][file]`,
              this.attachments[i].file
            );
            if (
              this.attachments &&
              this.attachments[i] &&
              this.attachments[i].name
            ) {
              formData.append(
                `attachments[${i}][name]`,
                this.attachments[i].name
              );
            }
          }
        }
      }
      let requestURL = "milestone-invoice";

      if (this.milestone_invoice_id) {
        requestURL = `milestone-invoice/${this.milestone_invoice_id}`;
      }
      _this.$store
        .dispatch(POST, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.invoiceData.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.invoiceData.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.invoiceData.remark && this.invoiceData.remark.length > 99) {
        e.preventDefault();
      }
    },
  },
  components: {
    Dialog,
    DatePicker,
  },
  mounted() {},
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>
